// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   FormControl,
//   IconButton,
//   InputLabel,
//   MenuItem,
//   Modal,
//   Select,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TextField,
//   TextareaAutosize,
//   styled,
//   tableCellClasses,
// } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { apiMachineMaster } from "../api/api.addmachine";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import { apigetMachine } from "../api/apigetmachine";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import { apiGetShift } from "../api/api.getshift";
// import { useAuthCheck } from "../utils/Auth";
// import DeleteConfirmationModal from "./deletemodal";
// import { apiGetTbm } from "../api/api.gettimebasealertdetail";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#1FAEC5",
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// export default function Tbm() {
//   const [machinedata, setMachinedata] = useState([]);
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [addOpen, setAddOpen] = useState(false);
//   const [addUpdate, setAddUpdate] = useState(false);

//   const [machineID, setMachineID] = useState("");
//   const [plantNO, setPlantNO] = useState("");
//   const [machineName, setMachineName] = useState("");
//   const [machineCode, setMachineCOde] = useState("");
//   const [lineProductionCount, setLineProductionCount] = useState("");
//   const [lineName, setLineName] = useState("");
//   const [severity, setSeverity] = useState("success");
//   const [shiftData, setShiftData] = useState([]);
//   const [error, setError] = useState(null);

//   const [tbmData, setTbmData] = useState([]);
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false);

//   const [refreshData, setRefreshData] = useState(false);
//   const [updatedMachineData, setUpdatedMachineData] = useState({
//     machineId: "",
//     plantNo: "",
//     lineNo: "",
//     machineName: "",
//     displayMachineName: "",
//     lineProductionCount: "",
//     cycleTime: "",
//   });

//   useAuthCheck();
//   useEffect(() => {
//     const getmachine = async () => {
//       try {
//         const result = await apigetMachine();
//         console.log(result?.data.data);
//         setMachinedata(result?.data.data);
//         console.log("machine", machinedata);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     return () => {
//       getmachine();
//     };
//   }, []);
//   useEffect(() => {
//     const getShift = async () => {
//       try {
//         const result = await apiGetShift();
//         console.log("shiftdata", result.data.data);
//         setShiftData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error");
//       }
//     };
//     getShift();
//   }, [refreshData]);
//   useEffect(() => {
//     const getTBM = async () => {
//       try {
//         const result = await apiGetTbm();
//         console.log(result.data.data, "TBM data");
//         setTbmData(result.data.data);
//       } catch (error) {
//         setError(error.message);
//         handleSnackbarOpen(error.message, "error in tbm");
//       }
//     };
//     getTBM();
//   }, []);
//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   const handlePlaneNOChange = (event) => {
//     setPlantNO(event.target.value);
//   };
//   const handleMachineIDChange = (event) => {
//     setMachineID(event.target.value);
//   };
//   const handleMachineNameChange = (event) => {
//     setMachineName(event.target.value);
//   };
//   const handleMachineCodeChange = (event) => {
//     setMachineCOde(event.target.value);
//   };
//   const handleLineNameChange = (event) => {
//     setLineName(event.target.value);
//   };
//   const handleAddSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const result = await apiMachineMaster(
//         machineCode,
//         machineID,
//         machineName,
//         plantNO,
//         lineProductionCount,
//         lineName
//       );
//       setAddOpen(false);
//       console.log(
//         "mcode,mid,plantno,lpc,linen",
//         machineCode,
//         machineID,
//         machineName,
//         plantNO,
//         lineProductionCount,
//         lineName
//       );
//       handleSnackbarOpen("Machine added successfully!", "success"); // Pass severity as "success"
//       console.log("response", result.data);
//     } catch (error) {
//       console.error("Error adding machine:", error);
//       handleSnackbarOpen("Error adding machine. Please try again.", "error"); // Pass severity as "error"
//     }
//   };
//   const handleEditClick = () => {
//     setAddUpdate(true);
//   };

//   const handleDeleteClick = (row) => {
//     // setDeleteLineId(row.lineNo);
//     setDeleteModalOpen(true);
//   };

//   return (
//     <div style={{ padding: "0px 20px" }}>
//       <div
//         style={{
//           display: "flex",
//           width: "100%",
//           alignItems: "center",
//           justifyContent: "space-between",
//           paddingTop: "5px",
//           paddingBottom: "5px",
//         }}
//       >
//         <h2>TBM</h2>

//         <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
//           <Button
//             onClick={() => setAddOpen(true)}
//             style={{
//               fontWeight: "500",
//               borderRadius: "4px",
//               color: "gray",
//               border: "2px solid gray",
//               padding: "5px",
//               marginBottom: "5px",
//             }}
//           >
//             {" "}
//             Add New &nbsp;{" "}
//             <FontAwesomeIcon
//               style={{ fontSize: "18px", color: "gray" }}
//               icon={faPlus}
//             />
//           </Button>
//         </div>
//       </div>
//       <Box>
//         <Table
//           size="small"
//           style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
//         >
//           <TableHead>
//             <TableRow>
//               <StyledTableCell className="table-cell">
//                 Parameter
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Start Date
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">End Date</StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Alert After Date
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">Email</StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Alert Message
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Alert Frequency
//               </StyledTableCell>
//               <StyledTableCell className="table-cell">
//                 Mobile Number
//               </StyledTableCell>

//               {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
//               <StyledTableCell className="table-cell">Edit</StyledTableCell>
//               <StyledTableCell className="table-cell">Delete</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {tbmData?.map((row, index) => (
//               <StyledTableRow key={index}>
//                 <StyledTableCell className="table-cell">
//                   {row.parameterName}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.startDate}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.endDate}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.alertAfterDate}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.emailId}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.alertMessage}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.alertFrequency}
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   {row.mobileNumber}
//                 </StyledTableCell>

//                 <StyledTableCell className="table-cell">
//                   <IconButton onClick={() => handleEditClick(row)}>
//                     <EditIcon />
//                   </IconButton>
//                 </StyledTableCell>
//                 <StyledTableCell className="table-cell">
//                   <IconButton onClick={() => handleDeleteClick(row.id)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <DeleteConfirmationModal
//           open={deleteModalOpen}
//           onClose={() => setDeleteModalOpen(false)}
//           // onConfirm={handleConfirmDelete}
//         />
//         <Modal open={addOpen} onClose={() => setAddOpen(false)}>
//           <div
//             style={{
//               borderRadius: "10px",

//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//             <button
//               onClick={() => setAddOpen(false)}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 cursor: "pointer",
//                 backgroundColor: "transparent",
//                 border: "none",
//                 fontSize: "30px",
//               }}
//             >
//               &times;
//             </button>
//             <h2>Add Alert</h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 label="Parameter "
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 // style={{ marginRight: "10px" }}
//               />

//               <TextField
//                 type="number"
//                 label="Mobile Number"
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 // style={{ marginRight: "10px" }}
//               />
//               {/* <MenuItem value={4}>Machine 4</MenuItem>
//           <MenuItem value={5}>Machine 5</MenuItem> */}
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             ></div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl>
//                 <TextField
//                   label="Start Time"
//                   type="datetime-local"
//                   defaultValue="2024-03-20T09:00"
//                   style={{ width: "225px" }}
//                 />
//               </FormControl>
//               <FormControl>
//                 <TextField
//                   label="End Time"
//                   type="datetime-local"
//                   defaultValue="2024-03-20T09:00"
//                   style={{ width: "225px" }}
//                 />
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl>
//                 <TextField
//                   label="Alert After Date"
//                   type="date"
//                   defaultValue="2024-03-20"
//                   style={{ width: "225px", marginRight: "10px" }}
//                 />
//               </FormControl>
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Alert Frequency</InputLabel>
//                 <Select value={machineCode} onChange={handleMachineCodeChange}>
//                   <MenuItem value="Daily">Daily</MenuItem>
//                   <MenuItem value="WeeKly">Weekly</MenuItem>
//                   <MenuItem value="Monthly">Monthly</MenuItem>
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 label="Email "
//                 type="email"
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 style={{ marginRight: "10px" }}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               {" "}
//               <TextField
//                 label="Alert Message "
//                 fullWidth
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 // style={{ marginLeft: "10px" }}
//               />
//             </div>

//             <Button
//               onClick={handleAddSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Add
//             </Button>
//           </div>
//         </Modal>
//         <Modal open={addUpdate} onClose={() => setAddUpdate(false)}>
//           <div
//             style={{
//               borderRadius: "10px",

//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               backgroundColor: "white",
//               padding: "20px",
//               minWidth: "500px",
//             }}
//           >
//             <button
//               onClick={() => setAddUpdate(false)}
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 cursor: "pointer",
//                 backgroundColor: "transparent",
//                 border: "none",
//                 fontSize: "30px",
//               }}
//             >
//               &times;
//             </button>
//             <h2>Update Alert</h2>
//             <hr />
//             <br />
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 label="Parameter "
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 // style={{ marginRight: "10px" }}
//               />

//               <TextField
//                 type="number"
//                 label="Mobile Number"
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             ></div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl>
//                 <TextField
//                   label="Start Time"
//                   type="datetime-local"
//                   defaultValue="2024-03-20T09:00"
//                   style={{ width: "225px" }}
//                 />
//               </FormControl>
//               <FormControl>
//                 <TextField
//                   label="End Time"
//                   type="datetime-local"
//                   defaultValue="2024-03-20T09:00"
//                   style={{ width: "225px" }}
//                 />
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <FormControl>
//                 <TextField
//                   label="Alert After Date"
//                   type="date"
//                   defaultValue="2024-03-20"
//                   style={{ width: "225px", marginRight: "10px" }}
//                 />
//               </FormControl>
//               <FormControl sx={{ width: "26ch" }}>
//                 <InputLabel>Alert Frequency</InputLabel>
//                 <Select value={machineCode} onChange={handleMachineCodeChange}>
//                   <MenuItem value="Daily">Daily</MenuItem>
//                   <MenuItem value="WeeKly">Weekly</MenuItem>
//                   <MenuItem value="Monthly">Monthly</MenuItem>
//                 </Select>
//               </FormControl>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               <TextField
//                 label="Email "
//                 type="email"
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 style={{ marginRight: "10px" }}
//               />
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginBottom: "10px",
//               }}
//             >
//               {" "}
//               <TextField
//                 label="Alert Message "
//                 fullWidth
//                 value={machineID}
//                 onChange={handleMachineIDChange}
//                 // style={{ marginLeft: "10px" }}
//               />
//             </div>

//             <Button
//               onClick={handleAddSubmit}
//               variant="contained"
//               color="primary"
//               style={{ marginTop: "20px" }}
//             >
//               Update
//             </Button>
//           </div>
//         </Modal>
//       </Box>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useAuthCheck } from "../utils/Auth";
import DeleteConfirmationModal from "./deletemodal";
import { alertApi } from "../api/alert";
import { format } from "date-fns";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Tbm() {
  const [tbmData, setTbmData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteAlertId, setDeleteAlertId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [alertData, setAlertData] = useState({
    parameterName: "",
    startDate: "",
    endDate: "",
    alertAfterDate: "",
    alertFrequency: "",
    alertMessage: "",
    emailId: "",
    mobileNumber: "",
  });

  useAuthCheck();

  useEffect(() => {
    fetchTbmData();
  }, [refreshData]);

  const fetchTbmData = async () => {
    try {
      const result = await alertApi.getAlerts();
      setTbmData(result.data.data);
      console.log("tbm data",tbmData)
    } catch (error) {
      handleSnackbarOpen("Error fetching alerts. Please try again.", "error");
    }
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAlertData((prevData) => ({
      ...prevData,
      [name]: name.includes("Date")
        ? format(new Date(value), "yyyy-MM-dd")
        : value,
    }));
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      await alertApi.addAlert(alertData);
      setAddOpen(false);
      handleSnackbarOpen("Alert added successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      handleSnackbarOpen("Error adding alert. Please try again.", "error");
    }
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedData = {
        ...alertData,
        startDate: alertData.startDate
          ? format(new Date(alertData.startDate), "yyyy-MM-dd")
          : null,
        endDate: alertData.endDate
          ? format(new Date(alertData.endDate), "yyyy-MM-dd")
          : null,
        alertAfterDate: alertData.alertAfterDate
          ? format(new Date(alertData.alertAfterDate), "yyyy-MM-dd")
          : null,
      };
      await alertApi.updateAlert(formattedData.id, formattedData);
      setUpdateOpen(false);
      handleSnackbarOpen("Alert updated successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      handleSnackbarOpen("Error updating alert. Please try again.", "error");
    }
  };

  const handleEditClick = (row) => {
    const formattedRow = {
      ...row,
      startDate: row.startDate
        ? format(new Date(row.startDate), "yyyy-MM-dd")
        : "",
      endDate: row.endDate ? format(new Date(row.endDate), "yyyy-MM-dd") : "",
      alertAfterDate: row.alertAfterDate
        ? format(new Date(row.alertAfterDate), "yyyy-MM-dd")
        : "",
    };
    setAlertData(formattedRow);
    setUpdateOpen(true);
  };
  const handleDeleteClick = (row) => {
    setDeleteAlertId(row.tbmId);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await alertApi.deleteAlert(deleteAlertId);
      handleSnackbarOpen("Alert deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      handleSnackbarOpen("Error deleting alert. Please try again.", "error");
    }
    setDeleteModalOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tbmData.length - page * rowsPerPage);

  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        margin:'20px 0px 20px 0px'
        }}
      >
        <h2>TBM</h2>
        <Button
          onClick={() => setAddOpen(true)}
          style={{
            fontWeight: "500",
            borderRadius: "4px",
            color: "gray",
            border: "2px solid gray",
            padding: "5px",
          }}
        >
          Add New &nbsp;
          <FontAwesomeIcon
            style={{ fontSize: "18px", color: "gray" }}
            icon={faPlus}
          />
        </Button>
      </div>

      <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
        <Table
          size="small"
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Parameter</StyledTableCell>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
              <StyledTableCell>Alert After Date</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Alert Message</StyledTableCell>
              <StyledTableCell>Alert Frequency</StyledTableCell>
              <StyledTableCell>Mobile Number</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tbmData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.parameterName}</StyledTableCell>
                  <StyledTableCell>{row.startDate}</StyledTableCell>
                  <StyledTableCell>{row.endDate}</StyledTableCell>
                  <StyledTableCell>{row.alertAfterDate}</StyledTableCell>
                  <StyledTableCell>{row.emailId}</StyledTableCell>
                  <StyledTableCell>{row.alertMessage}</StyledTableCell>
                  <StyledTableCell>{row.alertFrequency}</StyledTableCell>
                  <StyledTableCell>{row.mobileNumber}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={() => handleEditClick(row)}>
                      <EditIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={() => handleDeleteClick(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 }}>
                <StyledTableCell colSpan={10} style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {`No further data available`}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 1000]}
          component="div"
          count={tbmData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows per page:"
          nextIconButtonProps={{
            onClick: () => handleChangePage(null, page + 1),
            disabled: page === Math.ceil(tbmData.length / rowsPerPage) - 1,
          }}
          backIconButtonProps={{
            onClick: () => handleChangePage(null, page - 1),
            disabled: page === 0,
          }}
          SelectProps={{
            native: true,
            SelectDisplayProps: { "aria-label": "Rows per page" },
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
          nextIconButtonText="Next"
          backIconButtonText="Previous"
        />

        <Modal
          open={addOpen || updateOpen}
          onClose={() => (addOpen ? setAddOpen(false) : setUpdateOpen(false))}
        >
          <div
            style={{
              borderRadius: "10px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
            }}
          >
            <h2>{addOpen ? "Add Alert" : "Update Alert"}</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",

                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                label="Parameter"
                name="parameterName"
                value={alertData.parameterName}
                onChange={handleInputChange}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                fullWidth
                label="Start Date"
                name="startDate"
                type="date"
                value={alertData.startDate || ""}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",

                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                label="End Date"
                name="endDate"
                type="date"
                value={alertData.endDate || ""}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                fullWidth
                label="Alert After Date"
                name="alertAfterDate"
                type="date"
                value={alertData.alertAfterDate || ""}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",

                marginBottom: "10px",
              }}
            >
              <FormControl fullWidth style={{ marginBottom: "10px" }}>
                <InputLabel>Alert Frequency</InputLabel>
                <Select
                  name="alertFrequency"
                  value={alertData.alertFrequency}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TextField
              fullWidth
              label="Alert Message"
              name="alertMessage"
              value={alertData.alertMessage}
              onChange={handleInputChange}
              style={{ marginBottom: "10px" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",

                marginBottom: "10px",
              }}
            >
              <TextField
                fullWidth
                label="Email"
                name="emailId"
                type="email"
                value={alertData.emailId}
                onChange={handleInputChange}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                value={alertData.mobileNumber}
                onChange={handleInputChange}
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              />
            </div>
            <Button
              onClick={addOpen ? handleAddSubmit : handleUpdateSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              {addOpen ? "Add" : "Update"}
            </Button>
          </div>
        </Modal>

        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
        />

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </div>
  );
}
