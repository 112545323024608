import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
  TablePagination,
  tabClasses,
  Tab,
  Tabs,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteConfirmationModal from "./deletemodal";
import { apiGetShift } from "../api/api.getshift";
import { apiGetPlant } from "../api/api.getplant";
import { apiGetQualityRejection } from "../api/api.getqualityrejection";
import { apigetLines } from "../api/api.getline";
import { apiQualityRejection } from "../api/api.addqualityrejection";
import { parseISO, format } from "date-fns";
import { apiUpdateQualityRejection } from "../api/api.updateqrejection";
import { apiDeleteQualityRejection } from "../api/api.deleteqrejection";
import { useAuthCheck } from "../utils/Auth";
import { apiQLossHourly } from "../api/api.qlosshourly";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function QualityRejection() {
  const [machinedata, setMachinedata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [add2Open, setAdd2Open] = useState(false);

  const [updateOpen, setUpdateOpen] = useState(false);
  const [machineID, setMachineID] = useState("");
  const [machineName, setMachineName] = useState("");
  const [machineCode, setMachineCOde] = useState("");
  const [lineProductionCount, setLineProductionCount] = useState("");
  const [lineName, setLineName] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [qRejectionData, setQRejectionData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [selectedLine, setSelectedLine] = useState("");
  const [lineData, setLineData] = useState([]);
  const [deleteQRejectionId, setDeleteQRejectionId] = useState(null);
  const [filteredLineData, setFilteredLineData] = useState([]);
  const [filteredMachineData, setFilteredMachineData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [hourlyLossData, setHourlyLossData] = useState({
    lineNo: "",
    machineId: "",
    cdate: "23:01:1997",
    shiftNo: "",
    rejectionNo: "",
  });

  const [updatedQRejection, setUpdatedQRejection] = useState({
    plantNo: "",
    machineNo: "",
    shiftNo: "",
    lineNo: "",
    rejectionNo: "",
    date: "23:01:1997",
  });
  useAuthCheck();
  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setUpdatedQRejection((prevData) => ({
      ...prevData,
      [name]: value,
        date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("machine data:", result.data.data);
        setMachinedata(result?.data.data);
        console.log("machine", machinedata);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    return () => {
      getmachine();
    };
  }, [refreshData]);

  useEffect(() => {
    const getPlant = async () => {
      try {
        const result = await apiGetPlant();
        console.log("Result data plant:", result.data.data);
        setPlantData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getPlant();
  }, [refreshData]);

  useEffect(() => {
    const getShift = async () => {
      try {
        const result = await apiGetShift();
        console.log("shiftdata", result.data.data);
        setShiftData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getShift();
  }, [refreshData]);

  useEffect(() => {
    const getQualities = async () => {
      try {
        const result = await apiGetQualityRejection();
        console.log("qrejectiondata:", result.data.data);
        setQRejectionData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getQualities();
  }, [refreshData]);
  useEffect(() => {
    const getline = async () => {
      try {
        const result = await apigetLines();
        console.log("Result data line:", result.data.data); // Log the data property
        setLineData(result.data.data); // Set plantData to result.data
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getline();
  }, [refreshData]);
  useEffect(() => {
    if (updatedQRejection.lineNo) {
      const selectedLine = lineData.find(
        (line) => line.lineNo === updatedQRejection.lineNo
      );
      if (selectedLine) {
        setUpdatedQRejection((prevData) => ({
          ...prevData,
          plantNo: selectedLine.plantNo,
        }));
      }
    }
    if (updatedQRejection.lineNo) {
      const filteredMachines = machinedata.filter(
        (machine) => machine.lineNo === updatedQRejection.lineNo
      );
      setFilteredMachineData(filteredMachines);
    }
  }, [
    updatedQRejection.plantNo,
    updatedQRejection.lineNo,
    lineData,
    machinedata,
  ]);
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleHourlyLossChange = (e) => {
    const { name, value } = e.target;
    setHourlyLossData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const handleOkClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formattedFromDate = format(
        parseISO(hourlyLossData.cdate),
        "dd-MMM-yyyy"
      );

      const formattedRawData = {
        ...hourlyLossData,
        cdate: formattedFromDate,
        shiftId: parseInt(hourlyLossData.shiftId, 10), // Convert shiftId to an integer
      };
      // setAddOpen(false);
      console.log("formatted unknown loss data:", formattedRawData);
      const result = await apiQLossHourly(formattedRawData);

      // await getmachine();
      handleSnackbarOpen("Unkown loss Data fetched successfully!", "success"); // Pass severity as "success"
      // setLoading(false);
      console.log("Unknown loss response", result.data);
      setData(result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      // setLoading(false);
      console.error("Error getting unknown loss data:", error);
      handleSnackbarOpen(
        "Error fetching unknown loss data. Please try again.",
        "error"
      ); // Pass severity as "error"
    } finally {
      setLoading(false);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();

    try {
      const formattedDate = format(
        parseISO(updatedQRejection.date),
        "dd-MMM-yyyy"
      );
      const formattedQRData = {
        ...updatedQRejection,
        date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      };
      const result = await apiQualityRejection(formattedQRData);
      setAddOpen(false);
      setRefreshData((prev) => !prev);

      handleSnackbarOpen("Quality rejection added successfully!", "success"); // Pass severity as "success"
      console.log("response", result.data);
    } catch (error) {
      console.error("Error adding machine:", error);
      handleSnackbarOpen("Error quality rejection. Please try again.", "error"); // Pass severity as "error"
    }
  };
  const handleUpdateSubmit = async (event) => {
    event.preventDefault();

    try {
      const formattedDate = format(
        parseISO(updatedQRejection.date),
        "dd-MMM-yyyy"
      );
      const formattedQRData = {
        ...updatedQRejection,
        date: formattedDate,
      };
      const result = await apiUpdateQualityRejection(formattedQRData);
      setUpdateOpen(false);
      setRefreshData((prev) => !prev);

      handleSnackbarOpen("Quality reason updated successfully!", "success"); // Pass severity as "success"
      console.log("response", result.data);
    } catch (error) {
      console.error("Error adding machine:", error);
      handleSnackbarOpen(
        "Error updating quality rejection. Please try again.",
        "error"
      ); // Pass severity as "error"
    }
  };
  const handleEditClick = (row) => {
    setUpdatedQRejection(row);
    setUpdateOpen(true);
  };

  const handleDeleteClick = (row) => {
    console.log("deleting qr:", row.id);

    setDeleteQRejectionId(row.id);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await apiDeleteQualityRejection(deleteQRejectionId);
      handleSnackbarOpen("Quality rejection deleted successfully!", "success");
      setUpdatedQRejection({
        plantNo: "",
        machineNo: "",
        shiftNo: "",
        rejectionNo: "",
        date: "23:01:1997",
      });
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Plant:", error);
      handleSnackbarOpen(
        "Error deleting quality rejection. Please try again.",
        "error"
      );
    } finally {
      setDeleteModalOpen(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };
  const filteredMachines = machinedata.filter(
    (machine) => machine.lineNo === selectedLine
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalClose = () => {
    // Reset the form data
    setUpdatedQRejection({
      plantNo: "",
      machineNo: "",
      shiftNo: "",
      rejectionNo: "",
      date: "23:01:1997",
    });

    setAddOpen(false);
    setAdd2Open(false)
    setUpdateOpen(false);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, qRejectionData.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <h2>Quality Rejection</h2>
        <div style={{ display: "flex" }}>
          <div
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              display: "flex",
              marginRight: "10px",
            }}
          >
            <Button
              onClick={() => setAdd2Open(true)}
              style={{
                fontWeight: "500",
                borderRadius: "4px",
                color: "gray",
                border: "2px solid gray",
                padding: "5px",
                marginBottom: "5px",
              }}
            >
              {" "}
              Get Hourly
              <FontAwesomeIcon
                style={{ fontSize: "18px", color: "gray" }}
                icon={faPlus}
              />
            </Button>
          </div>

          <div
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={() => setAddOpen(true)}
              style={{
                fontWeight: "500",
                borderRadius: "4px",
                color: "gray",
                border: "2px solid gray",
                padding: "5px",
                marginBottom: "5px",
              }}
            >
              {" "}
              Add Shift Wise &nbsp;{" "}
              <FontAwesomeIcon
                style={{ fontSize: "18px", color: "gray" }}
                icon={faPlus}
              />
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="tabs"
          sx={{
            // Styling for the tabs container
            ".MuiTabs-indicator": {
              backgroundColor: "#1FAEC5", // Keeps the indicator same color as active tab
            },
          }}
        >
          <Tab
            label="Shift Wise"
            sx={{
              backgroundColor: tabIndex === 0 ? "#1FAEC5" : "lightgrey",
              color: tabIndex === 0 ? "white !important" : "black",
              "&:hover": {
                backgroundColor: tabIndex === 0 ? "#1FAEC5" : "grey",
                color: tabIndex === 0 ? "white !important" : "black",
              },
            }}
          />
          <Tab
            label="Hourly"
            sx={{
              backgroundColor: tabIndex === 1 ? "#1FAEC5" : "lightgrey",
              color: tabIndex === 1 ? "white !important" : "black",
              "&:hover": {
                backgroundColor: tabIndex === 1 ? "#1FAEC5" : "grey",
                color: tabIndex === 1 ? "white !important" : "black",
              },
            }}
          />
        </Tabs>
        {tabIndex === 0 && (
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">
                  Plant Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Machine Name{" "}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Rejection Number
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Create Date{" "}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Date Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Shift Name{" "}
                </StyledTableCell>
                {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
                <StyledTableCell className="table-cell">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qRejectionData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="table-cell">
                    {row.lineName}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.displayMachineName}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.rejectionNo}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.createdAt}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.processDate}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.shiftName}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                    className="table-cell"
                  >
                    <IconButton onClick={() => handleEditClick(row)}>
                      <EditIcon />
                    </IconButton>
                    <div
                      className="divider"
                      style={{
                        height: "20px",
                        width: "2px",
                        backgroundColor: "#0003",
                      }}
                    ></div>
                    <IconButton
                      style={{ color: "#FF3131" }}
                      onClick={() => handleDeleteClick(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-400px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {`No further data available`}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
        {tabIndex === 1 && (
          // Hourly tab content (placeholder)
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">
                  Plant Name
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Machine Name{" "}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Rejection Number
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Create Date{" "}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Date Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Shift Name{" "}
                </StyledTableCell>
                {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
                <StyledTableCell className="table-cell">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="table-cell">
                    {row.lineName}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.machineName}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.rejectionNo}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.createdAt}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.processDate}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.shiftName}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                    className="table-cell"
                  >
                    <IconButton onClick={() => handleEditClick(row)}>
                      <EditIcon />
                    </IconButton>
                    <div
                      className="divider"
                      style={{
                        height: "20px",
                        width: "2px",
                        backgroundColor: "#0003",
                      }}
                    ></div>
                    <IconButton
                      style={{ color: "#FF3131" }}
                      onClick={() => handleDeleteClick(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-400px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {`No further data available`}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qRejectionData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Modal open={updateOpen} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Reason</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedQRejection?.lineNo}
                  onChange={handleInputChange}
                >
                  {lineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedQRejection?.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachines.map((machine) => (
                    <MenuItem key={machine.id} value={machine.machineNo}>
                      {machine.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Shift Name</InputLabel>
                <Select
                  name="shiftNo"
                  value={updatedQRejection?.shiftNo}
                  onChange={handleInputChange}
                >
                  {shiftData.map((shift, index) => (
                    <MenuItem key={shift.shiftId} value={shift?.shiftId}>
                      {shift?.shiftName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="rejectionNo"
                label="Rejection Number "
                value={updatedQRejection?.rejectionNo}
                onChange={handleInputChange}
                // style={{ marginRight: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
             
              {/* <FormControl sx={{ minWidth: 225 }}>
                <TextField
                  name="date"
                  label="Start Date"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  value={updatedQRejection?.date}
                  onChange={handleInputChange}
                />
              </FormControl> */}
            </div>

            <Button
              onClick={handleUpdateSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update
            </Button>
          </div>
        </Modal>
        <Modal open={addOpen} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add Reason</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedQRejection?.lineNo}
                  onChange={handleInputChange}
                >
                  {lineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedQRejection?.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachines.map((machine) => (
                    <MenuItem key={machine.id} value={machine.machineNo}>
                      {machine.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Shift Name</InputLabel>
                <Select
                  name="shiftNo"
                  value={updatedQRejection?.shiftNo}
                  onChange={handleInputChange}
                >
                  {shiftData.map((shift, index) => (
                    <MenuItem key={shift.shiftId} value={shift?.shiftId}>
                      {shift?.shiftName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="rejectionNo"
                label="Rejection Number "
                value={updatedQRejection.rejectionNo}
                onChange={handleInputChange}
                // style={{ marginRight: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
            
              {/* <FormControl sx={{ minWidth: 225 }}>
                <TextField
                  name="date"
                  label="Start Date"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  value={updatedQRejection.date}
                  onChange={handleInputChange}
                />
              </FormControl> */}
            </div>

            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add
            </Button>
          </div>
        </Modal>
        <Modal open={add2Open} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Get Hourly </h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="lineNo"
                  value={hourlyLossData?.lineNo}
                  onChange={handleHourlyLossChange}
                >
                  {lineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={hourlyLossData?.machineNo}
                  onChange={handleHourlyLossChange}
                >
                  {filteredMachines.map((machine) => (
                    <MenuItem key={machine.id} value={machine.machineNo}>
                      {machine.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Shift Name</InputLabel>
                <Select
                  name="shiftNo"
                  value={hourlyLossData?.shiftNo}
                  onChange={handleHourlyLossChange}
                >
                  {shiftData.map((shift, index) => (
                    <MenuItem key={shift.shiftId} value={shift?.shiftId}>
                      {shift?.shiftName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 225 }}>
                <TextField
                  name="cdate"
                  label="Select Date"
                  type="date"
                  // defaultValue="2024-03-20T09:00"
                  value={hourlyLossData.cdate}
                  onChange={handleHourlyLossChange}
                />
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              {/* <TextField
                name="rejectionNo"
                label="Rejection Number "
                value={hourlyLossData.rejectionNo}
                onChange={handleHourlyLossChange}
                style={{ marginRight: "10px" }}
              /> */}
         
            </div>

            <Button
              onClick={handleOkClick}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Get
            </Button>
          </div>
        </Modal>
        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
