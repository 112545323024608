
import client from './client';

export const dashboardApi = {
  getDasboard: async () => {
    return await client.get('/dashboard/dashboardMessage');
  },
  addDashbard: async (dashboardData) => {
    return await client.post('/dashboard/addDashboardMessage', dashboardData);
  },
  updateDashboard: async (updateId, dashboardData) => {
    console.log('Updating dashboard with ID:', updateId);
    console.log('Update data:', dashboardData);
    return await client.put(`/dashboard/updateDashboardMessage/${updateId}`, dashboardData);
  },
  deleteDashboard: async (id) => {
    return await client.delete(`/dashboard/removeDashboardMessage/${id}`);
  },

  getCracker2Dasboard: async () => {
    return await client.get('/dashboard/cracker2Dashboard');
  },
  getCracker3Dasboard: async () => {
    return await client.get('/dashboard/cracker3Dashboard');
  },
  getFurnace2Dasboard: async () => {
    return await client.get('/dashboard/furnace2Dashboard');
  },
  getFurnace3Dasboard: async () => {
    return await client.get('/dashboard/furnace3Dashboard');
  },
  getAlertMessage:async()=>{
    return await client.get("/common/alertDetail")
  }
};