
import React, { useState, useEffect } from "react";
import "../assets/css/navbar.css";
import {
  faBars,
  faClose,
  faAngleDown,
  faAngleUp,
  faGear,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, SwipeableDrawer } from "@mui/material";
import blogo from "../assets/images/digineous2.png";

function NavBar() {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [plantArchitectureOpen, setPlantArchitectureOpen] = useState(false);

  const [UATOpen, setUATOpen] = useState(false);
  const [rawDataOpen, setRawDataOpen] = useState(false);
  const [productionOpen, setProductionOpen] = useState(false);
  const [method2Open, setMethod2Open] = useState(false);
  const [method1Open, setMethod1Open] = useState(false);
  const [report1, setReport1Open] = useState(false);

  const [report2, setReport2Open] = useState(false);
  const [userId, setUserId] = useState(null);

  const [state, setState] = React.useState({
    right: false,
  });

  const navigate = useNavigate();

  const handlePlantArchitectureClick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(!plantArchitectureOpen);
  };

  const handleUATClick = () => {
    setUATOpen(!UATOpen);

    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };

  const handleMethod2CLick = () => {
    setMethod2Open(!method2Open);
    setUATOpen(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };
  const handleMethod1CLick = () => {
    setMethod1Open(!method1Open);
    setUATOpen(false);
    setMethod2Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
  };

  const handleProductionLick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);

    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(!productionOpen);
  };
  const handleReportClick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);

    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(false);
    setReport1Open(!report1);
  };
  const handleReport2Click = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);

    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(false);
    setReport2Open(!report2);
  };
  const handleRawDataClick = () => {
    setRawDataOpen(!rawDataOpen);
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);

    setPlantArchitectureOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };
  const userID=localStorage.getItem("userID")
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userID") // Clear the token from localStorage
    navigate("/login"); // Navigate to the login page
  };
  const handleHideMenu = () => {
    setHideMenu(!hideMenu);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSettingsClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ right: open });
  };

  return (
    <nav>
      <div>
        <Link className="clogo" to="#">
          <img
            src={blogo}
            alt="Customer Logo"
            style={{ width: "140px", height: "45px" }}
          />
        </Link>
      </div>

      
      <div className="seticon">
        <FontAwesomeIcon
          style={{ fontSize: "25px", padding: "0px 40px", cursor: "pointer" }}
          icon={faBars}
          // onClick={toggleSidebar}
          onClick={toggleDrawer(true)}
        />
      </div>
 
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        
      <div className={`sidebar ${toggleDrawer(true) ? "open" : ""}`}>
          <List>
            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/dashboard"
              className={
                location.pathname === "/dashboard" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/smart_dashboard"
              className={
                location.pathname === "/smart_dashboard" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Smart Dashboard" />
            </ListItem>
            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleSettingsClick}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  style={{ cursor: "pointer" }}
                  Button
                  onClick={handlePlantArchitectureClick}
                >
                  <ListItemText primary="Plant Architecture" />
                  {plantArchitectureOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={plantArchitectureOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                  
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/Plant_master"
                      className={
                        location.pathname === "/plant_architecture/Plant_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Plant Master" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/linemaster"
                      className={
                        location.pathname ===
                        "plant_architecture/linemaster"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Line Master" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/machine_master"
                      className={
                        location.pathname ===
                        "/plant_architecture/machine_master"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Machine Master" />
                    </ListItem>
                   
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/devicemaster"
                      className={
                        location.pathname ===
                        "/plant_architecture/devicemaster"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Device Master" />
                    </ListItem>
                    <ListItem
                      Button
                      component={Link}
                      to="/plant_architecture/thresholds"
                      className={
                        location.pathname ===
                        "/plant_architecture/thresholds"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Threshold" />
                    </ListItem>
                    <ListItem
                  Button
                  component={Link}
                  to="/production_plan/shift_master"
                  className={
                    location.pathname === "/production_plan/shift_master"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Shift Master" />
                </ListItem>
                    <ListItem
                      style={{ marginBottom: "20px" }}
                      Button
                      component={Link}
                      to="/plant_architecture/device_status"
                      className={
                        location.pathname === "/plant_architecture/device_status"
                          ? "activeListItem"
                          : ""
                      }
                    >
                      <ListItemText primary="Device Status" />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>

        
         
            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/raw_data"
              className={
                location.pathname === "/raw_data" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Raw Data" />
            </ListItem>

           
 <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/admreports"
              className={
                location.pathname === "/admreports" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>

            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/tbm"
              className={
                location.pathname === "/tbm" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="TBM" />
            </ListItem>


            <ListItem
              onClick={toggleDrawer(false)}
              Button
              component={Link}
              to="/publishers"
              className={
                location.pathname === "/publishers" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Publisher" />
            </ListItem>

 

            {/*<ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleMethod1CLick}
              // className={location.pathname === "/raw_data" ? "activeListItem" : ""}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Method 1" />

              {method1Open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={method1Open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem Button component={Link} to="/method2/machine_input">
                <ListItemText primary="Machine Input" />
              </ListItem> 
              {userID !=="userpth"&&(
                <ListItem
                  Button
                  component={Link}
                  to="/method1/ghaziabad_ope_1"
                  className={
                    location.pathname === "/method1/ghaziabad_ope_1"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Ghaziabad OPE" />
                </ListItem>
                )}
                {userID !=="usergzb"&&(
                <ListItem
                  Button
                  component={Link}
                  to="/method1/pathredi_ope_1"
                  className={
                    location.pathname === "/method1/pathredi_ope_1"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Pathredi OPE" />
                </ListItem>
                )}
              </List>
            </Collapse>
            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleMethod2CLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Method 2" />

              {method2Open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={method2Open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/method2/machine_input"
                  className={
                    location.pathname === "/method2/machine_input"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Machine Input" />
                </ListItem>
                {userID !=="userpth"&&(
                <ListItem
                  Button
                  component={Link}
                  to="/method2/ghaziabad_ope_2"
                  className={
                    location.pathname === "/method2/ghaziabad_ope_2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Ghaziabad OPE(2) " />
                </ListItem>
                )}
                {userID !=="usergzb"&&(
                <ListItem
                  Button
                  component={Link}
                  to="/method2/pathredi_ope_2"
                  className={
                    location.pathname === "/method2/pathredi_ope_2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Pathredi OPE(2)" />
                </ListItem>
                )}
              </List>
            </Collapse>

            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleProductionLick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Production Plan" />

              {productionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={productionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/shift_master"
                  className={
                    location.pathname === "/production_plan/shift_master"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Shift Master" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/quality_rejection"
                  className={
                    location.pathname === "/production_plan/quality_rejection"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Quality Rejection " />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/machine_downtime"
                  className={
                    location.pathname === "/production_plan/machine_downtime"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Machine Downtime" />
                
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/unknown_loss"
                  className={
                    location.pathname === "/production_plan/unknown_loss"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Unknown Loss" />
                </ListItem>
{/* 
                <ListItem
                  Button
                  component={Link}
                  to="/production_plan/control_room"
                  className={
                    location.pathname === "/production_plan/control_room"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Control Room" />
                </ListItem> */}
                
            {/*    <ListItem
                  Button
                  component={Link}
                  to="/production_plan/holiday_list"
                  className={
                    location.pathname === "/production_plan/holiday_list"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Holiday List" />
                </ListItem>
              </List>
            </Collapse>*/}
          {  /*<ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleReportClick}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Reports M1" />

              {report1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={report1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/hour_bucket_m1"
                  className={
                    location.pathname === "/reportm1/hour_bucket_m1"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Hour Bucket M1" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/daily"
                  className={
                    location.pathname === "/reportm1/daily"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Daily" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/wholedayreportm1"
                  className={
                    location.pathname === "/reportm1/wholedayreportm1"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Whole Day" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/weekly"
                  className={
                    location.pathname === "/reportm1/weekly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Weekly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/monthly"
                  className={
                    location.pathname === "/reportm1/monthly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Monthly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/quaterly"
                  className={
                    location.pathname === "/reportm1/quaterly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Quaterly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm1/yearly"
                  className={
                    location.pathname === "/reportm1/yearly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Yearly" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              style={{ cursor: "pointer" }}
              Button
              onClick={handleReport2Click}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Reports M2" />

              {report2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={report2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/hour_bucket_m2"
                  className={
                    location.pathname === "/reportm2/hour_bucket_m2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Hourly Bucket M2" />
                  
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/daily"
                  className={
                    location.pathname === "/reportm2/daily"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Daily" />
                  
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/wholedayreportm2"
                  className={
                    location.pathname === "/reportm2/wholedayreportm2"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Whole Day" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/weekly"
                  className={
                    location.pathname === "/reportm2/weekly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Weekly " />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/monthly"
                  className={
                    location.pathname === "/reportm2/monthly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Monthly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/quaterly"
                  className={
                    location.pathname === "/reportm2/quaterly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Quaterly" />
                </ListItem>
                <ListItem
                  Button
                  component={Link}
                  to="/reportm2/yearly"
                  className={
                    location.pathname === "/reportm2/yearly"
                      ? "activeListItem"
                      : ""
                  }
                >
                  <ListItemText primary="Yearly" />
                </ListItem>
              </List>
            </Collapse>*/}
            <ListItem
              onClick={handleLogout} // Attach the logout function here
              Button
              className={
                location.pathname === "/logout" ? "activeListItem" : ""
              }
              style={{ cursor: "pointer", }}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOut} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </nav>
  );
}

export default NavBar;




// import React, { useState, useEffect } from "react";
// import "../assets/css/navbar.css";
// import {
//   faBars,
//   faClose,
//   faAngleDown,
//   faAngleUp,
//   faGear,
//   faSignOut,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link, useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Collapse from "@mui/material/Collapse";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import SettingsIcon from "@mui/icons-material/Settings";
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";
// import { Button, SwipeableDrawer } from "@mui/material";
// import blogo from "../assets/images/Digineous_logo.png";

// function NavBar() {
//   const location = useLocation();
//   const [clicked, setClicked] = useState(false);
//   const [hideMenu, setHideMenu] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [open, setOpen] = useState(false);
//   const [plantArchitectureOpen, setPlantArchitectureOpen] = useState(false);

//   const [UATOpen, setUATOpen] = useState(false);
//   const [rawDataOpen, setRawDataOpen] = useState(false);
//   const [productionOpen, setProductionOpen] = useState(false);
//   const [method2Open, setMethod2Open] = useState(false);
//   const [method1Open, setMethod1Open] = useState(false);
//   const [report1, setReport1Open] = useState(false);

//   const [report2, setReport2Open] = useState(false);

//   const [state, setState] = React.useState({
//     right: false,
//   });

//   const navigate = useNavigate();

//   const handlePlantArchitectureClick = () => {
//     setUATOpen(false);
//     setMethod2Open(false);
//     setMethod1Open(false);
//     setProductionOpen(false);
//     setRawDataOpen(false);
//     setPlantArchitectureOpen(!plantArchitectureOpen);
//   };

//   const handleUATClick = () => {
//     setUATOpen(!UATOpen);

//     setMethod2Open(false);
//     setMethod1Open(false);
//     setProductionOpen(false);
//     setRawDataOpen(false);
//     setPlantArchitectureOpen(false);
//   };

//   const handleMethod2CLick = () => {
//     setMethod2Open(!method2Open);
//     setUATOpen(false);
//     setMethod1Open(false);
//     setProductionOpen(false);
//     setRawDataOpen(false);
//     setPlantArchitectureOpen(false);
//   };
//   const handleMethod1CLick = () => {
//     setMethod1Open(!method1Open);
//     setUATOpen(false);
//     setMethod2Open(false);
//     setProductionOpen(false);
//     setRawDataOpen(false);
//     setPlantArchitectureOpen(false);
//   };

//   const handleProductionLick = () => {
//     setUATOpen(false);
//     setMethod2Open(false);
//     setMethod1Open(false);

//     setRawDataOpen(false);
//     setPlantArchitectureOpen(false);
//     setProductionOpen(!productionOpen);
//   };
//   const handleReportClick = () => {
//     setUATOpen(false);
//     setMethod2Open(false);
//     setMethod1Open(false);

//     setRawDataOpen(false);
//     setPlantArchitectureOpen(false);
//     setProductionOpen(false);
//     setReport1Open(!report1);
//   };
//   const handleReport2Click = () => {
//     setUATOpen(false);
//     setMethod2Open(false);
//     setMethod1Open(false);

//     setRawDataOpen(false);
//     setPlantArchitectureOpen(false);
//     setProductionOpen(false);
//     setReport2Open(!report2);
//   };
//   const handleRawDataClick = () => {
//     setRawDataOpen(!rawDataOpen);
//     setUATOpen(false);
//     setMethod2Open(false);
//     setMethod1Open(false);
//     setProductionOpen(false);

//     setPlantArchitectureOpen(false);
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleClick = () => {
//     setClicked(!clicked);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem("token"); // Clear the token from localStorage
//     navigate("/login"); // Navigate to the login page
//   };
//   const handleHideMenu = () => {
//     setHideMenu(!hideMenu);
//   };

//   const toggleSidebar = () => {
//     setSidebarOpen(!sidebarOpen);
//   };

//   const handleSettingsClick = () => {
//     setOpen(!open);
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event &&
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }

//     setState({ right: open });
//   };

//   return (
//     <nav>
//       <div>
//         <Link className="clogo" to="#">
//           <img
//             src={blogo}
//             alt="Customer Logo"
//             style={{ width: "140px", height: "45px" }}
//           />
//         </Link>
//       </div>

//       {/*<div className="mylinks">
//         <ul id="navbar" className={clicked ? "active" : ""}>
//           <li>
//             {" "}
//             <Link
//               to="/operations"
//               className={location.pathname === "/operations" ? "active" : ""}
//             >
//               Operations
//             </Link>
//           </li>

//           <li>
//             {" "}
//             <Link
//               to="/cbm"
//               className={location.pathname === "/cbm" ? "active" : ""}
//             >
//               CBM
//             </Link>
//           </li>
//           <li>
//             {" "}
//             <Link
//               to="/facilitymanagement"
//               className={
//                 location.pathname === "/facilitymanagement" ? "active" : ""
//               }
//             >
//               Facility Management
//             </Link>
//           </li>
//         </ul>
//       </div>
//             */}
//       <div className="seticon">
//         <FontAwesomeIcon
//           style={{ fontSize: "25px", padding: "0px 40px", cursor: "pointer" }}
//           icon={faBars}
//           // onClick={toggleSidebar}
//           onClick={toggleDrawer(true)}
//         />
//       </div>
//       {isMobile && (
//         <div id="mobile">
//           <FontAwesomeIcon
//             id="bar"
//             icon={clicked ? faClose : faBars}
//             onClick={handleClick}
//             style={{ cursor: "pointer", fontSize: "26px" }}
//           />
//         </div>
//       )}

//       <SwipeableDrawer
//         anchor={"right"}
//         open={state["right"]}
//         onClose={toggleDrawer(false)}
//         onOpen={toggleDrawer(true)}
//       >
//         {/* Sidebar */}
//         {/* <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
//         <button className="closeButton" onClick={toggleSidebar}>
//           <FontAwesomeIcon style={{ fontSize: "30px" }} icon={faClose} />
//         </button>
//       </div>
//       */}

//         <div className={`sidebar ${toggleDrawer(true) ? "open" : ""}`}>
//           <List>
//             <ListItem
//               onClick={toggleDrawer(false)}
//               Button
//               component={Link}
//               to="/raw_data"
//               className={
//                 location.pathname === "/raw_data" ? "activeListItem" : ""
//               }
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Raw Data" />
//             </ListItem>

//             <ListItem
//               onClick={toggleDrawer(false)}
//               Button
//               component={Link}
//               to="/hour_bucket_m1"
//               className={
//                 location.pathname === "/hour_bucket_m1" ? "activeListItem" : ""
//               }
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Hour Bucket M1" />
//             </ListItem>
//             <ListItem
//               Button
//               onClick={toggleDrawer(false)}
//               component={Link}
//               to="/hour_bucket_m2"
//               className={
//                 location.pathname === "/hour_bucket_m2" ? "activeListItem" : ""
//               }
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Hour Bucket M2" />
//             </ListItem>

//             <ListItem
//               style={{ cursor: "pointer" }}
//               Button
//               onClick={handleMethod1CLick}
//               // className={location.pathname === "/raw_data" ? "activeListItem" : ""}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Method 1" />

//               {method1Open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={method1Open} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 {/* <ListItem Button component={Link} to="/method2/machine_input">
//                 <ListItemText primary="Machine Input" />
//               </ListItem> */}
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/method1/ghaziabad_ope_1"
//                   className={
//                     location.pathname === "/method1/ghaziabad_ope_1"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Ghaziabad OPE" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/method1/pathredi_ope_1"
//                   className={
//                     location.pathname === "/method1/pathredi_ope_1"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Pathredi OPE" />
//                 </ListItem>
//               </List>
//             </Collapse>
//             <ListItem
//               style={{ cursor: "pointer" }}
//               Button
//               onClick={handleMethod2CLick}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Method 2" />

//               {method2Open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={method2Open} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/method2/machine_input"
//                   className={
//                     location.pathname === "/method2/machine_input"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Machine Input" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/method2/ghaziabad_ope_2"
//                   className={
//                     location.pathname === "/method2/ghaziabad_ope_2"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Ghaziabad OPE(2) " />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/method2/pathredi_ope_2"
//                   className={
//                     location.pathname === "/method2/pathredi_ope_2"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Pathredi OPE(2)" />
//                 </ListItem>
//               </List>
//             </Collapse>

//             <ListItem
//               style={{ cursor: "pointer" }}
//               Button
//               onClick={handleProductionLick}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Production Plan" />

//               {productionOpen ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={productionOpen} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/production_plan/shift_master"
//                   className={
//                     location.pathname === "/production_plan/shift_master"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Shift Master" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/production_plan/quality_rejection"
//                   className={
//                     location.pathname === "/production_plan/quality_rejection"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Quality Rejection " />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/production_plan/machine_downtime"
//                   className={
//                     location.pathname === "/production_plan/machine_downtime"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Machine Downtime" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/production_plan/holiday_list"
//                   className={
//                     location.pathname === "/production_plan/holiday_list"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Holiday List" />
//                 </ListItem>
//               </List>
//             </Collapse>
//             <ListItem
//               style={{ cursor: "pointer" }}
//               Button
//               onClick={handleSettingsClick}
//             >
//               <ListItemIcon>
//                 <SettingsIcon />
//               </ListItemIcon>
//               <ListItemText primary="Settings" />
//               {open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={open} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItem
//                   style={{ cursor: "pointer" }}
//                   Button
//                   onClick={handlePlantArchitectureClick}
//                 >
//                   <ListItemText primary="Plant Architecture" />
//                   {plantArchitectureOpen ? <ExpandLess /> : <ExpandMore />}
//                 </ListItem>
//                 <Collapse
//                   in={plantArchitectureOpen}
//                   timeout="auto"
//                   unmountOnExit
//                 >
//                   <List component="div" disablePadding>
//                     <ListItem
//                       Button
//                       component={Link}
//                       to="/plant_architecture/plant_master"
//                       className={
//                         location.pathname === "/plant_architecture/plant_master"
//                           ? "activeListItem"
//                           : ""
//                       }
//                     >
//                       <ListItemText primary="Plant Master" />
//                     </ListItem>
//                     <ListItem
//                       Button
//                       component={Link}
//                       to="/plant_architecture/line_master"
//                       className={
//                         location.pathname === "/plant_architecture/line_master"
//                           ? "activeListItem"
//                           : ""
//                       }
//                     >
//                       <ListItemText primary="Line Master" />
//                     </ListItem>
//                     <ListItem
//                       Button
//                       component={Link}
//                       to="/plant_architecture/machine_master"
//                       className={
//                         location.pathname ===
//                         "/plant_architecture/machine_master"
//                           ? "activeListItem"
//                           : ""
//                       }
//                     >
//                       <ListItemText primary="Machine Master" />
//                     </ListItem>
//                     <ListItem
//                       Button
//                       component={Link}
//                       to="/plant_architecture/part_master"
//                       className={
//                         location.pathname === "/plant_architecture/part_master"
//                           ? "activeListItem"
//                           : ""
//                       }
//                     >
//                       <ListItemText primary="Part Master" />
//                     </ListItem>
//                     <ListItem
//                       style={{ marginBottom: "120px" }}
//                       Button
//                       component={Link}
//                       to="/plant_architecture/frdc_master"
//                       className={
//                         location.pathname === "/plant_architecture/frdc_master"
//                           ? "activeListItem"
//                           : ""
//                       }
//                     >
//                       <ListItemText primary="Change Overtime Master" />
//                     </ListItem>
//                   </List>
//                 </Collapse>
//               </List>
//             </Collapse>
//             <ListItem
//               style={{ cursor: "pointer" }}
//               Button
//               onClick={handleReportClick}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Reports M1" />

//               {report1 ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={report1} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm1/daily"
//                   className={
//                     location.pathname === "/reportm1/daily"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Daily" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm1/weekly"
//                   className={
//                     location.pathname === "/reportm1/weekly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Weekly" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm1/monthly"
//                   className={
//                     location.pathname === "/reportm1/monthly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Monthly" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm1/quaterly"
//                   className={
//                     location.pathname === "/reportm1/quaterly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Quaterly" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm1/yearly"
//                   className={
//                     location.pathname === "/reportm1/yearly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Yearly" />
//                 </ListItem>
//               </List>
//             </Collapse>
//             <ListItem
//               style={{ cursor: "pointer" }}
//               Button
//               onClick={handleReport2Click}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Reports M2" />

//               {report2 ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={report2} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm2/daily"
//                   className={
//                     location.pathname === "/reportm2/daily"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Daily" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm2/weekly"
//                   className={
//                     location.pathname === "/reportm2/weekly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Weekly " />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm2/monthly"
//                   className={
//                     location.pathname === "/reportm2/monthly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Monthly" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm2/quaterly"
//                   className={
//                     location.pathname === "/reportm2/quaterly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Quaterly" />
//                 </ListItem>
//                 <ListItem
//                   Button
//                   component={Link}
//                   to="/reportm2/yearly"
//                   className={
//                     location.pathname === "/reportm2/yearly"
//                       ? "activeListItem"
//                       : ""
//                   }
//                 >
//                   <ListItemText primary="Yearly" />
//                 </ListItem>
//               </List>
//             </Collapse>
//             <ListItem
//               onClick={handleLogout} // Attach the logout function here
//               Button
//               className={
//                 location.pathname === "/logout" ? "activeListItem" : ""
//               }
//               style={{ cursor: "pointer" }}
//             >
//               <ListItemIcon>
//                 <FontAwesomeIcon icon={faSignOut} />
//               </ListItemIcon>
//               <ListItemText primary="Logout" />
//             </ListItem>
//           </List>
//         </div>
//       </SwipeableDrawer>
//     </nav>
//   );
// }

// export default NavBar;

