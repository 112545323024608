import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  tableCellClasses,
  styled,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { apigetMachine } from "../api/apigetmachine";
import { apigetLines } from "../api/api.getline";
import { apigetRawData } from "../api/api.getrawdata";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";
import "../assets/css/forms.css";
import admlogo from "../assets/images/adm.png";
import digineouslogo from "../assets/images/digineous2.png";
import { commonService } from "../api";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};
export default function ADMReports() {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [reportData, setReportData] = useState({
    lineNo: "",
    shiftId: "",
    startDate:  getCurrentDate(),
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedLine, setSelectedLine] = useState("");
  const [loading, setLoading] = useState(false);
  useAuthCheck();
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };



  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setReportData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formattedStartDate = format(
        parseISO(reportData.startDate),
        "dd-MMM-yyyy"
      );

      const requestData = {
        shiftId: parseInt(reportData.shiftId),
        startDate: formattedStartDate,
      };
      console.log("request data:",requestData)

      let result;
      switch (reportData.lineNo) {
        case "furnace2report":
          result = await commonService.postFurnace2Report(requestData);
          break;
        case "furnace3report":
          result = await commonService.postFurnace3Report(requestData);
          break;
        case "cracker2report":
          result = await commonService.postCracker2Report(requestData);
          break;
        case "cracker3report":
          result = await commonService.postCracker3Report(requestData);
          break;
        default:
          throw new Error("Invalid device selected");
      }

      handleSnackbarOpen("Report data fetched successfully!", "success");
      console.log("Report response", result.data);
      setData(result.data);
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error getting report data:", error);
      handleSnackbarOpen(
        "Error fetching report data. Please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const formatData = (data) => {
    return data.map((row) => ({
      "Machine Id": parseInt(row.machineId),
      "Date Time": row.dateTime,
      "Cycle Time": parseFloat(row.cycleTime),
    }));
  };

  const downloadApiCall = async () => {
    const { lineNo, machineId, fromDate, toDate } = reportData;
    const formattedFromDate = format(parseISO(fromDate), "dd-MMM-yyyy");
    const formattedToDate = format(parseISO(toDate), "dd-MMM-yyyy");

    return await apigetRawData({
      lineNo,
      machineId,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item>
          <img
            src={admlogo}
            alt="Left Company Logo"
            style={{ height: "80px" }}
          />
        </Grid>
        <Grid item>
          <img
            src={digineouslogo}
            alt="Right Company Logo"
            style={{ height: "70px" }}
          />
        </Grid>
      </Grid>
      <div>
        <h2>Production Cum Process Report </h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item xs={6} sm={3}>
          {" "}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Device</InputLabel>
            <Select
              name="lineNo"
              value={reportData?.lineNo}
              onChange={handleInputChange}
            >
              <MenuItem value="furnace2report">Furnace 2</MenuItem>

              <MenuItem value="furnace3report">Furnace 3</MenuItem>
              <MenuItem value="cracker2report">Cracker 2</MenuItem>
              <MenuItem value="cracker3report">Cracker 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              name="startDate"
              type="date"
              placeholder="Start Date"
              value={reportData?.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          {" "}
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Shift</InputLabel>

            <Select
              name="shiftId"
              value={reportData?.shiftId}
              onChange={handleInputChange}
            >
              <MenuItem value="1">Day</MenuItem>

              <MenuItem value="2">Night</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          {" "}
          <Button variant="contained" color="primary" onClick={handleAddSubmit}>
            OK
          </Button>
        </Grid>
      </Grid>
      <DownloadButton
        apiCall={downloadApiCall}
        formatData={formatData}
        fileName="RawDataReport.xlsx"
      />

      <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size="small"
            style={{
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">
                  DateTime
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Zone 1 Temp (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Zone 2 Temp (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Zone 3 Temp (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Zone 4 Temp (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Cooling Water Temp (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Hydrogen Dew Point (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Nitrogen Dew Point (°C)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Hydrogen Purity (%)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Hydrogen Flow (Nm³/h)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Nitrogen Flow (Nm³/h)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Conveyor Frequency (Hz)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Tray Count (NOS)
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Chiller Pump Pressure
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Ignitor Inlet 1
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Ignitor Inlet 2
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Ignitor Outlet 1
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                Ignitor Outlet 2
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.dateTime}</StyledTableCell>
                    <StyledTableCell>{row.zone1Temperature}</StyledTableCell>
                    <StyledTableCell>{row.zone2Temperature}</StyledTableCell>
                    <StyledTableCell>{row.zone3Temperature}</StyledTableCell>
                    <StyledTableCell>{row.zone4Temperature}</StyledTableCell>
                    <StyledTableCell>{row.coolingWaterTemperature}</StyledTableCell>
                    <StyledTableCell>{row.hydrogenDewPoint}</StyledTableCell>
                    <StyledTableCell>{row.nitrogenDewPoint}</StyledTableCell>
                    <StyledTableCell>{row.hydrogenPurity}</StyledTableCell>
                    <StyledTableCell>{row.hydrogenFlow}</StyledTableCell>
                    <StyledTableCell>{row.nitrogenFlow}</StyledTableCell>
                    <StyledTableCell>{row.conveyorSpeed}</StyledTableCell>
                    <StyledTableCell>{row.trayCount}</StyledTableCell>
                    <StyledTableCell>{row.chillerPumpPressur}</StyledTableCell>
                    <StyledTableCell>{row.inlet1}</StyledTableCell>
                    <StyledTableCell>{row.inlet2}</StyledTableCell>
                    <StyledTableCell>{row.outlet1}</StyledTableCell>
                    <StyledTableCell>{row.outlet2}</StyledTableCell>


                  </StyledTableRow>
                ))}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={18} style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {`No further data available`}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 1000]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows per page:"
          nextIconButtonProps={{
            onClick: () => handleChangePage(null, page + 1),
            disabled: page === Math.ceil(data.length / rowsPerPage) - 1,
          }}
          backIconButtonProps={{
            onClick: () => handleChangePage(null, page - 1),
            disabled: page === 0,
          }}
          SelectProps={{
            native: true,
            SelectDisplayProps: { "aria-label": "Rows per page" },
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
          nextIconButtonText="Next"
          backIconButtonText="Previous"
        />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
