import React from "react";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

// const DownloadButton = ({ apiCall, formatData, fileName }) => {
//   const handleDownload = async () => {
//     try {
//       const result = await apiCall();
//       console.log(result)
//       const data = result.data.data;

//       const formattedData = formatData(data);

//       const ws = XLSX.utils.json_to_sheet(formattedData);
//       const wb = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(wb, ws, "Report");

//       const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//       const blob = new Blob([wbout], { type: "application/octet-stream" });
//       saveAs(blob, fileName);
//     } catch (error) {
//       console.error("Error generating report:", error);
//     }
//   };

//   return (
//     <Button variant="contained" style={{backgroundColor:'#540b4b'}} onClick={handleDownload}>
//       Download Report
//     </Button>
//   );
// };

// export default DownloadButton;


const DownloadButton = ({ apiCall, formatData, fileName }) => {
  const handleDownload = async () => {
    try {
      // Ensure the apiCall is a function returning a promise
      const result = await apiCall();
      console.log("Result from API call:", result); // Log the result to debug

      // Ensure we correctly access the data depending on the API response structure
      const data = result?.data || result; // Adjust based on how your API returns data

      // Format the data for Excel
      const formattedData = formatData(data);

      // Create a worksheet and workbook
      const ws = XLSX.utils.json_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Report");

      // Write the workbook to a binary array and create a Blob
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });

      // Trigger the download
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  return (
    <Button variant="contained" style={{ backgroundColor: '#540b4b' }} onClick={handleDownload}>
      Download Report
    </Button>
  );
};

export default DownloadButton;
