import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
  TablePagination,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DeleteConfirmationModal from "./deletemodal";
import { apiGetShift } from "../api/api.getshift";
import { apiGetPlant } from "../api/api.getplant";
import { apigetLines } from "../api/api.getline";
import { apiAddDownTime } from "../api/api.adddowntime";
import { apiGetDownTime } from "../api/api.getdowntime";
import { apiDeleteDownTime } from "../api/api.deleteddowntime";
import { apiUpdateDTime } from "../api/api.updatedowntime";
import { useAuthCheck } from "../utils/Auth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


export default function MachineDownTime() {
  const [machinedata, setMachinedata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [machineID, setMachineID] = useState("");
  const [plantNO, setPlantNO] = useState("");
  const [machineName, setMachineName] = useState("");
  const [machineCode, setMachineCOde] = useState("");
  const [lineProductionCount, setLineProductionCount] = useState("");
  const [lineName, setLineName] = useState("");
  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [downtimeData, setDowntimeData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [selectedLine, setSelectedLine] = useState("");
  const [filteredLineData, setFilteredLineData] = useState([]);
  const [filteredMachineData, setFilteredMachineData] = useState([]);
  const [deleteDowntimeId, setDeleteDownTimeId] = useState(null);

  const [updatedDtimeData, setUpdatedDtimeData] = useState({
    plantNo: "",
    lineNo: "",
    machineNo: "",
    shiftId: "",
    machineDownDate: "",
    totalDownTime: "",
    startTime: "23:01:1997",
    endTime: "23:01:1997",
    reason: "",
  });

  useAuthCheck();
  // useEffect(() => {
  //   const getmachine = async () => {
  //     try {
  //       const result = await apigetMachine();
  //       console.log("machine data:", result.data.data);
  //       setMachinedata(result.data.data);
  //     } catch (error) {
  //       setError(error.message);
  //       handleSnackbarOpen(error.message, "error");
  //     }
  //   };
  //   return () => {
  //     getmachine();
  //   };
  // }, [refreshData]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [machines, plants, shifts, lines, downtimes] = await Promise.all([
          apigetMachine(),
          apiGetPlant(),
          apiGetShift(),
          apigetLines(),
          apiGetDownTime(),
        ]);
        setMachinedata(machines.data.data);
        setPlantData(plants.data.data);
        setShiftData(shifts.data.data);
        setLineData(lines.data.data);
        setDowntimeData(downtimes.data.data);
        console.log("downtime data",downtimeData)
      } catch (error) {
        handleSnackbarOpen(error.message, "error");
      }
    };
    fetchData();
  }, [refreshData]);
  
  // useEffect(() => {
  //   const getPlant = async () => {
  //     try {
  //       const result = await apiGetPlant();
  //       console.log("Result data plant:", result.data.data);
  //       setPlantData(result.data.data);
  //     } catch (error) {
  //       setError(error.message);
  //       handleSnackbarOpen(error.message, "error");
  //     }
  //   };
  //   getPlant();
  // }, [refreshData]);

  // useEffect(() => {
  //   const getShift = async () => {
  //     try {
  //       const result = await apiGetShift();
  //       console.log("shiftdata", result.data.data);
  //       setShiftData(result.data.data);
  //     } catch (error) {
  //       setError(error.message);
  //       handleSnackbarOpen(error.message, "error");
  //     }
  //   };
  //   getShift();
  // }, [refreshData]);
  // const filteredLines = lineData.filter(
  //   (line) => line.plantNo === selectedPlant
  // );

  // // Filter machineData based on selected line
  // const filteredMachines = machinedata.filter(
  //   (machine) => machine.lineNo === selectedLine
  // );

  useEffect(() => {
    if (updatedDtimeData.plantNo) {
      const filteredLines = lineData.filter(
        (line) => line.plantNo === updatedDtimeData.plantNo
      );
      setFilteredLineData(filteredLines);
    }
    if (updatedDtimeData.lineNo) {
      const filteredMachines = machinedata.filter(
        (machine) => machine.lineNo === updatedDtimeData.lineNo
      );
      setFilteredMachineData(filteredMachines);
    }
  }, [
    updatedDtimeData.plantNo,
    updatedDtimeData.lineNo,
    lineData,
    machinedata,
  ]);
  // useEffect(() => {
  //   const getline = async () => {
  //     try {
  //       const result = await apigetLines();
  //       console.log("Result data line:", result.data.data); // Log the data property
  //       setLineData(result.data.data); // Set plantData to result.data
  //     } catch (error) {
  //       setError(error.message);
  //       handleSnackbarOpen(error.message, "error");
  //     }
  //   };
  //   getline();
  // }, [refreshData]);
  // useEffect(() => {
  //   const getDowntime = async () => {
  //     try {
  //       const result = await apiGetDownTime();
  //       console.log("Result data downtime:", result.data.data);
  //       setDowntimeData(result.data.data);
  //     } catch (error) {
  //       setError(error.message);
  //       handleSnackbarOpen(error.message, "error");
  //     }
  //   };
  //   getDowntime();
  // }, [refreshData]);
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  // const handleMachineIDChange = (event) => {
  //   setMachineID(event.target.value);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDtimeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "startTime" || name === "endTime") {
      const { startTime, endTime } = updatedDtimeData;
      if (startTime && endTime) {
        const start = new Date(`2000-01-01T${startTime}`);
        const end = new Date(`2000-01-01T${endTime}`);
        const totalDownTimeInMilliseconds = end - start;
        const hours = Math.floor(
          (totalDownTimeInMilliseconds / (1000 * 60 * 60)) % 24
        );
        const minutes = Math.floor(
          (totalDownTimeInMilliseconds / (1000 * 60)) % 60
        );
        const seconds = Math.floor((totalDownTimeInMilliseconds / 1000) % 60);
        const totalDownTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        setUpdatedDtimeData((prevData) => ({
          ...prevData,
          totalDownTime,
        }));
      }
    }
  };
  const handleUpdateSubmit = async (event) => {
    event.preventDefault();

    let startTime = updatedDtimeData.startTime;
    let endTime = updatedDtimeData.endTime;

    try {
      // Parse startTime and endTime into Date objects to validate and format
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      // Check if startTime and endTime are valid date objects
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error("Invalid start time or end time");
      }

      // Format startTime and endTime to HH:mm:ss format
      startTime = startDate.toTimeString().slice(0, 8); // HH:mm:ss
      endTime = endDate.toTimeString().slice(0, 8); // HH:mm:ss

      // Prepare the payload for the API request
      const payload = {
        id:updatedDtimeData.id,
        plantNo: updatedDtimeData.plantNo,
        lineNo: updatedDtimeData.lineNo,
        machineNo: updatedDtimeData.machineNo,
        machineDownDate: startDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }),
        totalDownTime: calculateTotalDownTime(startTime, endTime),
        startTime: startTime,
        endTime: endTime,
        reason: updatedDtimeData.reason,
      };

      const result = await apiUpdateDTime(payload);

      setAddOpen(false); // Close the modal after successful addition

      // Display success message
      handleSnackbarOpen("Machine downtime updated successfully!", "success");
      setRefreshData((prev) => !prev);
      console.log("response", result.data);
    } catch (error) {
      console.error("Error updating machine downtime:", error);
      handleSnackbarOpen(
        "Error adding machine downtime. Please try again.",
        "error"
      );
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();

    // Validate and format startTime and endTime
    let startTime = updatedDtimeData.startTime;
    let endTime = updatedDtimeData.endTime;

    try {
      // Parse startTime and endTime into Date objects to validate and format
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      // Check if startTime and endTime are valid date objects
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error("Invalid start time or end time");
      }

      // Format startTime and endTime to HH:mm:ss format
      startTime = startDate.toTimeString().slice(0, 8); // HH:mm:ss
      endTime = endDate.toTimeString().slice(0, 8); // HH:mm:ss

      // Prepare the payload for the API request
      const payload = {
        plantNo: updatedDtimeData.plantNo,
        lineNo: updatedDtimeData.lineNo,
        machineNo: updatedDtimeData.machineNo,
        machineDownDate: startDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }), // Format machineDownDate as "DD-Mon-YYYY"
        totalDownTime: calculateTotalDownTime(startTime, endTime), // Calculate total downtime
        startTime: startTime,
        endTime: endTime,
        reason: updatedDtimeData.reason,
      };

      const result = await apiAddDownTime(payload);

      setAddOpen(false); // Close the modal after successful addition

      // Display success message
      handleSnackbarOpen("Machine downtime added successfully!", "success");
      setUpdatedDtimeData({
        plantNo: "",
        lineNo: "",
        machineNo: "",
        shiftId: "",
        machineDownDate: "",
        totalDownTime: "",
        startTime: "23:01:1997",
        endTime: "23:01:1997",
        reason: "",
      });
      
      setRefreshData((prev) => !prev);
      console.log("response", result.data);
    } catch (error) {
      console.error("Error adding machine downtime:", error);
      handleSnackbarOpen(
        "Error adding machine downtime. Please try again.",
        "error"
      );
    }
  };
  const calculateTotalDownTime = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    const totalDownTimeInMilliseconds = end - start;
    const hours = Math.floor(
      (totalDownTimeInMilliseconds / (1000 * 60 * 60)) % 24
    );
    const minutes = Math.floor(
      (totalDownTimeInMilliseconds / (1000 * 60)) % 60
    );
    const seconds = Math.floor((totalDownTimeInMilliseconds / 1000) % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  const handleEditClick = (row) => {
    setUpdatedDtimeData(row)
    setUpdateOpen(true);
  };

  const handleDeleteClick = (row) => {
    console.log("deleting downtime:", row.id);
    setDeleteDownTimeId(row.id);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await apiDeleteDownTime(deleteDowntimeId);
      handleSnackbarOpen("Machine downtime deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Machine:", error);
      handleSnackbarOpen(
        "Error deleting Machine downtime. Please try again.",
        "error"
      );
    } finally {
      setDeleteModalOpen(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleModalClose = () => {
    // Reset the form data
    setUpdatedDtimeData({
      plantNo: "",
      lineNo: "",
      machineNo: "",
      shiftId: "",
      machineDownDate: "",
      totalDownTime: "",
      startTime: "23:01:1997",
      endTime: "23:01:1997",
      reason: "",
    });
    
    
    setAddOpen(false); 
    setUpdateOpen(false)
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, downtimeData.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        <h2>Machine Downtime</h2>

        <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <Button
            onClick={() => setAddOpen(true)}
            style={{
              fontWeight: "500",
              borderRadius: "4px",
              color: "gray",
              border: "2px solid gray",
              padding: "5px",
              marginBottom: "5px",
            }}
          >
            {" "}
            Add New &nbsp;{" "}
            <FontAwesomeIcon
              style={{ fontSize: "18px", color: "gray" }}
              icon={faPlus}
            />
          </Button>
        </div>
      </div>
      <Box  sx={{  maxHeight: "500px", overflow: "auto" }}>
        <Table
          size="small"
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell className="table-cell">
                Plant Name
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Line Name{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Machine Name
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Start Time
              </StyledTableCell>
              <StyledTableCell className="table-cell">End Time</StyledTableCell>
              <StyledTableCell className="table-cell">Reason </StyledTableCell>
              {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
              <StyledTableCell className="table-cell">Edit</StyledTableCell>
              <StyledTableCell className="table-cell">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {downtimeData.length === 0
              ? // Render skeleton loaders when data is still loading
                Array.from(Array(5).keys()).map((index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton animation="wave" />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : downtimeData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell className="table-cell">
                        {row.plantName}
                      </StyledTableCell>{" "}
                      <StyledTableCell className="table-cell">
                        {row.lineName}
                      </StyledTableCell>{" "}
                      <StyledTableCell className="table-cell">
                        {row.displayMachineName}
                      </StyledTableCell>{" "}
                      <StyledTableCell className="table-cell">
                        {row.startDownDate}
                      </StyledTableCell>{" "}
                      <StyledTableCell className="table-cell">
                        {row.endDownDate}
                      </StyledTableCell>{" "}
                      <StyledTableCell className="table-cell">
                        {row.reason}
                      </StyledTableCell>{" "}
                      <StyledTableCell className="table-cell">
                        <IconButton onClick={() => handleEditClick(row)}>
                          <EditIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell className="table-cell">
                        <IconButton onClick={() => handleDeleteClick(row)}style={{ color: "#FF3131" }}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 }}>
                <StyledTableCell colSpan={8} style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      // right: "-400px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {`No further data available`}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={downtimeData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
         </Box>
        <DeleteConfirmationModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
        />

        <Modal open={updateOpen} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Update Reason</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="plantNo"
                  value={updatedDtimeData.plantNo}
                  onChange={handleInputChange}
                >
                  {plantData.map((plant, index) => (
                    <MenuItem key={plant.plantNo} value={plant?.plantNo}>
                      {plant?.plantName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Line Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedDtimeData.lineNo}
                  onChange={handleInputChange}
                >
                  {filteredLineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedDtimeData.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachineData.map((machine) => (
                    <MenuItem
                      key={machine.machineId}
                      value={machine?.machineNo}
                    >
                      {machine?.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl>
                <TextField
                  label="Start Time"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  style={{ width: "225px" }}
                  name="startTime"
                  value={updatedDtimeData.startTime}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <TextField
                  label="End Time"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  name="endTime"
                  value={updatedDtimeData.endTime}
                  onChange={handleInputChange}
                  style={{ width: "225px" }}
                />
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Reason "
                name="reason"
                value={updatedDtimeData.reason}
                onChange={handleInputChange}
              />
            </div>

            <Button
              onClick={handleUpdateSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Update Downtime
            </Button>
          </div>
        </Modal>
        <Modal open={addOpen} onClose={handleModalClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              minWidth: "500px",
              borderRadius: "10px",
            }}
          >
            <button
              onClick={handleModalClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
              }}
            >
              &times;
            </button>
            <h2>Add Reason</h2>
            <hr />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Plant Name</InputLabel>
                <Select
                  name="plantNo"
                  value={updatedDtimeData.plantNo}
                  onChange={handleInputChange}
                >
                  {plantData.map((plant, index) => (
                    <MenuItem key={plant.plantNo} value={plant?.plantNo}>
                      {plant?.plantName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Line Name</InputLabel>
                <Select
                  name="lineNo"
                  value={updatedDtimeData.lineNo}
                  onChange={handleInputChange}
                >
                  {filteredLineData.map((line) => (
                    <MenuItem key={line.id} value={line.lineNo}>
                      {line.lineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl sx={{ width: "26ch" }}>
                <InputLabel>Machine Name</InputLabel>
                <Select
                  name="machineNo"
                  value={updatedDtimeData.machineNo}
                  onChange={handleInputChange}
                >
                  {filteredMachineData.map((machine, index) => (
                    <MenuItem key={machine.machineId} value={machine.machineNo}>
                      {machine.displayMachineName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <FormControl>
                <TextField
                  label="Start Time"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  style={{ width: "225px" }}
                  name="startTime"
                  value={updatedDtimeData.startTime}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <TextField
                  label="End Time"
                  type="datetime-local"
                  // defaultValue="2024-03-20T09:00"
                  style={{ width: "225px" }}
                  name="endTime"
                  value={updatedDtimeData.endTime}
                  onChange={handleInputChange}
                />
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Reason "
                name="reason"
                value={updatedDtimeData.reason}
                onChange={handleInputChange}
              />
            </div>

            <Button
              onClick={handleAddSubmit}
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
            >
              Add Downtime
            </Button>
          </div>
        </Modal>
     
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
