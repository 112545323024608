// import client from './client';

// export const alert = {
//   addAlert: async (alertData) => {
//     try {
//       const response = await client.post('/alert/addTimeBaseAlert', {
//         parameterName: alertData.parameterName,
//         startDate: alertData.startDate,
//         endDate: alertData.endDate,
//         alertAfterDate: alertData.alertAfterDate,
//         alertFrequency: alertData.alertFrequency,
//         alertMessage: alertData.alertMessage,
//         emailId: alertData.emailId,
//         mobileNumber: alertData.mobileNumber
//       });
//       return response.data;
//     } catch (error) {
//       console.error('Error adding alert:', error);
//       throw error;
//     }
//   }
// };


import client from './client';

export const alertApi = {
  getAlerts: async () => {
    return await client.get('/alert/timeBaseAlertDetail');
  },
  addAlert: async (alertData) => {
    return await client.post('/alert/addTimeBaseAlert', alertData);
  },
  updateAlert: async (id, alertData) => {
    return await client.put(`/alert/updateTimeBaseAlert/${id}`, alertData);
  },
  deleteAlert: async (id) => {
    return await client.delete(`/alert/removeTimeBaseAlert/${id}`);
  }
};