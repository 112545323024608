import React from 'react';
import { useAuthCheck } from '../utils/Auth';

export default function Welcome() {
  useAuthCheck()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', marginTop: '200px' }}>
      <h1>Welcome To</h1>
      <h1>Digineous Technologies Private Limited</h1>
    </div>
  );
}
