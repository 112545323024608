import axios from "axios";
import { baseUrl } from "./baseUrl";

export const apiMonthlyReportsM2 = async ({
  lineNo,
  machineId,
  
}) => {
  const url = baseUrl + "/common/monthWiseM2Data";
  try {
    const token = localStorage.getItem("token");

    const data = await axios.post(
      url,
      {
        lineNo: lineNo,
        machineNo: machineId,
       
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.data;
  } catch (error) {
    console.error("Error during getting raw data:", error);
    throw error;
  }
};
