import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  Tab,
  Tabs,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useAuthCheck } from "../utils/Auth";

import { commonService } from "../api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Dashboard() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [furnaceDetail, setFurnaceDetail] = useState(null);
  const [furnace3Detail, setFurnace3Detail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tabIndex, setTabIndex] = useState(0);

  useAuthCheck();

  useEffect(() => {
    const fetchFurnace2Detail = async () => {
      try {
        setLoading(true);
        const response = await commonService.getFurnace2Detail();
        console.log("Furnace 2 detail:", response.data);
        setFurnaceDetail(response.data);
      } catch (err) {
        setError(
          err.message || "An error occurred while fetching furnace details."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFurnace2Detail();
  }, []);
  useEffect(() => {
    const fetchFurnace3Detail = async () => {
      try {
        setLoading(true);
        const response = await commonService.getFurnace3Detail();
        console.log("Furnace 3 detail:", response.data);
        setFurnace3Detail(response.data);
      } catch (err) {
        setError(
          err.message || "An error occurred while fetching furnace details."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFurnace3Detail();
  }, []);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(rowsPerPage, furnaceDetail.length - page * rowsPerPage);
  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          margin:'20px 0px 20px 0px '
        }}
      ></div>
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="tabs"
          sx={{
            ".MuiTabs-indicator": {
              backgroundColor: "#1FAEC5",
            },
          }}
        >
          <Tab
            label="Furnace 2"
            sx={{
              backgroundColor: tabIndex === 0 ? "#1FAEC5" : "lightgrey",
              color: tabIndex === 0 ? "white !important" : "black",
              "&:hover": {
                backgroundColor: tabIndex === 0 ? "#1FAEC5" : "grey",
                color: tabIndex === 0 ? "white !important" : "black",
              },
            }}
          />
          <Tab
            label="Furnace 3"
            sx={{
              backgroundColor: tabIndex === 1 ? "#1FAEC5" : "lightgrey",
              color: tabIndex === 1 ? "white !important" : "black",
              "&:hover": {
                backgroundColor: tabIndex === 1 ? "#1FAEC5" : "grey",
                color: tabIndex === 1 ? "white !important" : "black",
              },
            }}
          />
        </Tabs>
        {tabIndex === 0 && (
          <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>

          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">SNO</StyledTableCell>
                <StyledTableCell className="table-cell">
                  FURNACE 2{" "}
                </StyledTableCell>
                <StyledTableCell className="table-cell">VALUE</StyledTableCell>
                <StyledTableCell className="table-cell">UOM </StyledTableCell>
                <StyledTableCell className="table-cell"></StyledTableCell>
                <StyledTableCell className="table-cell">
                  CRACKER 2{" "}
                </StyledTableCell>
                {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
                <StyledTableCell className="table-cell">VALUE</StyledTableCell>
                <StyledTableCell className="table-cell">UOM</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {furnaceDetail?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="table-cell">
                    {row.sNo}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.furnace2}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.valueFurnace2}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.unitFurnace2}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.processDate}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.cracker2}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.valueCracker2}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.unitCracker2}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
           
            </TableBody>
          </Table>
          </Box>
        )}
        {tabIndex === 1 && (
          // Hourly tab content (placeholder)
          <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>

          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell className="table-cell">SNO</StyledTableCell>
                <StyledTableCell className="table-cell">
                  FURNACE 3{" "}
                </StyledTableCell>
                <StyledTableCell className="table-cell">VALUE</StyledTableCell>
                <StyledTableCell className="table-cell">UOM </StyledTableCell>
                <StyledTableCell className="table-cell"></StyledTableCell>
                <StyledTableCell className="table-cell">
                  CRACKER 3{" "}
                </StyledTableCell>
                {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
                <StyledTableCell className="table-cell">VALUE</StyledTableCell>
                <StyledTableCell className="table-cell">UOM</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {furnace3Detail.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell className="table-cell">
                    {row.sNo}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.furnace3}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.valueFurnace3}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.unitFurnace3}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.processData}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.cracker3}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.valueCracker3}
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    {row.unitCracker3}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          
            </TableBody>
          </Table>
          </Box>
        )}

      
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
