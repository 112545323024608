import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  tableCellClasses,
  styled,
  TablePagination,
  Button,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { apigetMachine } from "../api/apigetmachine";
import { apiGetOpe1 } from "../api/api.getope1";
import MuiAlert from "@mui/material/Alert";
import { apigetLines } from "../api/api.getline";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function GhaziabadOpe() {
  const [opeData, setOpeData] = useState([]);
  const [machineId, setMachineId] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");

  const [machineData, setMachineData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [lineData, setLineData] = useState([]);
  const [lineNo, setLineNo] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  useAuthCheck();
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log("Result data machine:", result.data.data);

        let lineNo = null;

        const filteredMachines = result.data.data.filter((machine) => {
          console.log("machine.lineNo:", machine.lineNo);
          if (machine.lineName === "Ghaziabad") {
            lineNo = machine.lineNo;
          }
          return machine.lineName === "Ghaziabad";
        });

        if (lineNo !== null) {
          setLineNo(lineNo);
        }

        console.log("Filtered machines:", filteredMachines);
        console.log("lineNo for Ghaziabad:", lineNo);

        setMachineData(filteredMachines);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getmachine();
  }, [refreshData]);

  const handleMachineInputChange = async (event) => {
    const selectedMachineId = event.target.value;
    setMachineId(selectedMachineId);
    const lineId = lineNo;
    setLoading(true);
    try {
      console.log("lid,mid", lineId, selectedMachineId);
      const result = await apiGetOpe1(lineId, selectedMachineId);
      console.log("Ope Data gzb1:", result.data.data);
      setOpeData(result.data.data);
      handleSnackbarOpen("Ope Data fetched successfully!", "success");
    } catch (error) {
      setError(error.message);
      handleSnackbarOpen(error.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const downloadApiCall = async () => {
    if (lineNo && machineId) {
      try {
        // setLoading(true);
        const data = await apiGetOpe1(lineNo, machineId);
        // setLoading(false);
        return data.data; // Returning the actual data to be downloaded
      } catch (error) {
        // setLoading(false);
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    }
  };
  const formatData = (data) => {
    return data.map((row) => ({
      "M Id": row.mid,
      "Date Time": row.dateTime,
      "Ope Period For U": parseFloat(row.cycleTime),
      "AD": row.adData,
      "Seq Of Time Stamp": row.adDiffData,
      "DCT%": parseFloat(row.dctPercent),
      "Loss Type For Ope": row.lossTypeForOpe,
      "Part A": parseFloat(row.partA),
      "Part B": parseFloat(row.partB),
      "Part C": parseFloat(row.partC),
      "Part D ": parseFloat(row.partD),
      "Part E": row.partE,
      "Part F ": row.partF,
      "Part G ": row.partG,
      "Part H": row.partH,
      "Part I ": row.partI,
      "Part J ": row.partJ,
      "Test": row.test,
      "Frcd Cycletime ": parseFloat(row.frcdCycleTime),
      "F Partname": row.fPartName,
      "VAT": parseFloat(row.fCycleTime),
      "Category": row.category,
      "A Loss": parseFloat(row.aLoss),
      "P Loss": parseFloat(row.pLoss),
    }));
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, opeData.length - page * rowsPerPage);

  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "5px",
          paddingBottom: "15px",
        }}
      >
        <h2>Ghaziabad Ope </h2>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControl
          sx={{ width: "30ch", margonTop: "20px", marginBottom: "20px" }}
        >
          <InputLabel>Select Machine</InputLabel>
          <Select
            name="machineId"
            value={machineId}
            onChange={handleMachineInputChange}
          >
            {machineData.map((id) => (
              <MenuItem key={id.id} value={id.machineId}>
                {id.displayMachineName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ marginLeft: "30px", marginBottom: "20px" }}>
          <DownloadButton
            apiCall={downloadApiCall}
            formatData={formatData}
            fileName="Ghaziabad Ope 1.xlsx"
          />
        </div>
      </div>
      <Box sx={{ maxHeight: "500px", overflow: "auto" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table
            size="small"
            style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
          >
            <TableHead style={{ backgroundColor: "#0002" }}>
              <TableRow>
                <StyledTableCell className="table-cell">
                  Date Time
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  Ope Period for U
                </StyledTableCell>
                <StyledTableCell className="table-cell">AD</StyledTableCell>
                <StyledTableCell className="table-cell">
                  Seq of Time Stamp
                </StyledTableCell>
                <StyledTableCell className="table-cell">DCT%</StyledTableCell>
                <StyledTableCell className="table-cell">
                  Loss type for OPE
                </StyledTableCell>
                {/* <StyledTableCell className="table-cell">
                Value Adding Operating Time
              </StyledTableCell> */}
                <StyledTableCell className="table-cell">Part A</StyledTableCell>
                <StyledTableCell className="table-cell">Part B</StyledTableCell>
                <StyledTableCell className="table-cell">Part C</StyledTableCell>
                <StyledTableCell className="table-cell">Part D</StyledTableCell>
                <StyledTableCell className="table-cell">Part E</StyledTableCell>
                <StyledTableCell className="table-cell">Part F</StyledTableCell>
                <StyledTableCell className="table-cell">Part G</StyledTableCell>
                <StyledTableCell className="table-cell">Part H</StyledTableCell>
                <StyledTableCell className="table-cell">Part I</StyledTableCell>
                <StyledTableCell className="table-cell">Part J</StyledTableCell>
                <StyledTableCell className="table-cell">Test</StyledTableCell>
                {/* <StyledTableCell className="table-cell">Min time</StyledTableCell> */}
                <StyledTableCell className="table-cell">
                  Frcd Cycletime
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  F Part Name
                </StyledTableCell>
                {/* <StyledTableCell className="table-cell">
                F Partname
              </StyledTableCell> */}
                <StyledTableCell className="table-cell">VAT</StyledTableCell>
                <StyledTableCell className="table-cell">
                  Category
                </StyledTableCell>
                <StyledTableCell className="table-cell">A Loss</StyledTableCell>
                <StyledTableCell className="table-cell">P Loss</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {opeData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell className="table-cell">
                      {row.dateTime}
                    </StyledTableCell>

                    <StyledTableCell className="table-cell">
                      {row.cycleTime}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.adData}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.adDiffData}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.dctPercent}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.lossTypeForOpe}
                    </StyledTableCell>
                    {/* <StyledTableCell className="table-cell">
                    {row.fCycleTime}
                  </StyledTableCell> */}
                    <StyledTableCell className="table-cell">
                      {row.partA}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partB}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partC}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partD}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partE}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partF}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partG}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partH}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partI}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.partJ}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.test}
                    </StyledTableCell>
                    {/* <StyledTableCell className="table-cell">
                    {row.minTime}
                  </StyledTableCell> */}
                    <StyledTableCell className="table-cell">
                      {row.frcdCycleTime}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.fPartName}
                    </StyledTableCell>
                    {/* <StyledTableCell className="table-cell">
                    {row.fPartname}
                  </StyledTableCell> */}
                    <StyledTableCell className="table-cell">
                      {row.fCycleTime}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.category}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.aLoss}
                    </StyledTableCell>
                    <StyledTableCell className="table-cell">
                      {row.pLoss}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,1000]}
          component="div"
          count={opeData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
