

import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
  Alert,
  AlertTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { dashboardApi } from "../api/dashboard";
import '../assets/css/smartdashboard.css'

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 0.3s ease-in-out",
  borderRadius: "10px",
  background: "linear-gradient(to right, #f5f7fa, lightgrey)",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SmartDashboard = () => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [alert, setAlert] = useState("");
  const [furnace2Detail, setFurnace2Detail] = useState(null);
  const [furnace3Detail, setFurnace3Detail] = useState(null);
  const [cracker2Detail, setCracker2Detail] = useState(null);
  const [cracker3Detail, setCracker3Detail] = useState(null);
  const [alertDetail, setAlertDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateTimes, setDateTimes] = useState({
    furnace2: "",
    furnace3: "",
    cracker2: "",
    cracker3: "",
  });

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const [furnace2Res, furnace3Res, cracker2Res, cracker3Res, alertRes] =
          await Promise.all([
            dashboardApi.getFurnace2Dasboard(),
            dashboardApi.getFurnace3Dasboard(),
            dashboardApi.getCracker2Dasboard(),
            dashboardApi.getCracker3Dasboard(),
            dashboardApi.getAlertMessage(),
          ]);
        setFurnace2Detail(
          mapData(
            furnace2Res.data.data,
            "furnace2",
            "valueFurnace2",
            "unitFurnace2"
          )
        );
        setFurnace3Detail(
          mapData(
            furnace3Res.data.data,
            "furnace3",
            "valueFurnace3",
            "unitFurnace3"
          )
        );
        setCracker2Detail(
          mapData(
            cracker2Res.data.data,
            "cracker2",
            "valueCracker2",
            "unitCracker2"
          )
        );
        setCracker3Detail(
          mapData(
            cracker3Res.data.data,
            "cracker3",
            "valueCracker3",
            "unitCracker3"
          )
        );
        setDateTimes({
          furnace2: extractDateTime(furnace2Res.data.data, "furnace2"),
          furnace3: extractDateTime(furnace3Res.data.data, "furnace3"),
          cracker2: extractDateTime(cracker2Res.data.data, "cracker2"),
          cracker3: extractDateTime(cracker3Res.data.data, "cracker3"),
        });
        setAlertDetail(alertRes.data.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching details.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, []);

  const mapData = (data, key, valueKey, unitKey) => {
    const mappedData = {};
    data.forEach((item) => {
      mappedData[item[key]] = {
        value: parseFloat(item[valueKey]),
        unit: item[unitKey],
      };
    });
    return mappedData;
  };

  const extractDateTime = (data, key) => {
    const dateTimeItem = data.find((item) => item[key] === "Date Time");
    return dateTimeItem
      ? dateTimeItem[`value${capitalizeFirstLetter(key)}`]
      : "";
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const screens = [
    {
      title: "Furnace 2",
      data: furnace2Detail,
      dateTime: dateTimes.furnace2,
    },
    {
      title: "Cracker 2",
      data: cracker2Detail,
      dateTime: dateTimes.cracker2,
    },
    {
      title: "Furnace 3",
      data: furnace3Detail,
      dateTime: dateTimes.furnace3,
    },
    {
      title: "Cracker 3",
      data: cracker3Detail,
      dateTime: dateTimes.cracker3,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentScreen((prev) => (prev + 1) % 4);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, p: 3, bgcolor: "#f0f2f5" }}>
      {loading && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {screens[currentScreen]?.title}
          </Typography>
          <Typography variant="h4" color="text.secondary">
            {screens[currentScreen]?.dateTime}
          </Typography>
        </Paper>
      </Box>

      <Grid container spacing={3}>
        {screens[currentScreen]?.data &&
          Object.entries(screens[currentScreen].data)
            .slice(0, -1)
            .map(([key, { value, unit }]) => (
              <Grid item xs={12} sm={6} md={4} lg={2.4} key={key}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h4" component="div" gutterBottom>
                      {key} ({unit})
                    </Typography>
                    <hr />
                    <Typography
                      variant="h3"
                      sx={{ fontWeight: "bold", color: "#1976d2" }}
                    >
                      {value} 
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
      </Grid>

      <Box mt={3} sx={{width:'100%'}}>
        <Alert
          severity="info"
          sx={{ width: "100%", margin: "0 auto", boxShadow: 3 }}
        >
          <AlertTitle style={{ fontSize: "25px"}}>Alert</AlertTitle>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", border: "1px solid #e0e0e0" }}
          >
            <Table sx={{width:'100%'}}>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{fontSize:'22px'}}>Machine Name</StyledTableCell>
                  <StyledTableCell style={{fontSize:'22px'}}>Parameter Name</StyledTableCell>
                  <StyledTableCell style={{fontSize:'22px'}}>Value</StyledTableCell>
                  <StyledTableCell style={{fontSize:'22px'}}>Message</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alertDetail.map((alert, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell style={{fontSize:'22px'}}>{alert.machine_name}</StyledTableCell>
                    <StyledTableCell style={{fontSize:'22px'}}>{alert.parameter_name}</StyledTableCell>
                    <StyledTableCell style={{fontSize:'22px'}}>{alert.value}</StyledTableCell>
                    <StyledTableCell style={{fontSize:'22px'}}>{alert.message}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Alert>
      </Box>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 3 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default SmartDashboard;
