
import client from './client';

export const commonService = {

  getFurnace2Detail: (params) =>
    client.get('/common/furnace2Detail', { params })
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching furnace2Detail:', error);
        throw error;
      }),

  getFurnace3Detail: (params) =>
    client.get('/common/furnace3Detail', { params })
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching furnace3Detail:', error);
        throw error;
      }),

  // Add the new POST API endpoints
  postFurnace2Report: (data) =>
    client.post('/common/furnace2Report', data)
      .then(response => response.data)
      .catch(error => {
        console.error('Error posting furnace2Report:', error);
        throw error;
      }),

  postFurnace3Report: (data) =>
    client.post('/common/furnace3Report', data)
      .then(response => response.data)
      .catch(error => {
        console.error('Error posting furnace3Report:', error);
        throw error;
      }),

  postCracker2Report: (data) =>
    client.post('/common/cracker2Report', data)
      .then(response => response.data)
      .catch(error => {
        console.error('Error posting cracker2Report:', error);
        throw error;
      }),

  postCracker3Report: (data) =>
    client.post('/common/cracker3Report', data)
      .then(response => response.data)
      .catch(error => {
        console.error('Error posting cracker3Report:', error);
        throw error;
      }),
};